import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@/store';
import { type UserInfo, type Language } from '@/app_models/user';

export interface UserState {
  UserInfo: UserInfo & { is_oidc_user: boolean }
  language: Language
}

const initialState: UserState = {
  UserInfo: {
    username: '',
    displayName: '',
    email: '',
    permission: [],
    token: '',
    is_oidc_user: false
  },
  language: 'zh'
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserInfo: (state, action) => {
      state.UserInfo = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    }
  }
});

export const { setUserInfo, setLanguage } = userSlice.actions;

export const selectUserInfo = (state: RootState): UserInfo => state.user.UserInfo;
export const selectLanguage = (state: RootState): UserState['language'] => state.user.language;

export default userSlice.reducer;
