import '@/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { type FC, lazy, Suspense } from 'react';

const Login = lazy(async () => await import('@/pages/login/index'));
const Container = lazy(async () => await import('@/pages/container/index'));

const App: FC = () => {
  return (
    <Router>
      <Suspense>
        <Routes>
          <Route path='/login' element={<Login />}></Route>
          <Route
            path="/*"
            key="container"
            element={<Container />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
