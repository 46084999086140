import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from '@/store';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/style/public.less';
import '@/lang';
import { updateLanguage } from './lang';

const defaultData = {
  borderRadius: 2,
  colorPrimary: '#1890ff',
  colorText: 'rgba(0, 0, 0, 0.65)',
  fontFamily: 'system-ui'
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// 根据当前的语言环境返回对应的 antd 的标点符号和文案等配置
function getAntdLocale (): any {
  const locale = store.getState().user.language;
  switch (locale) {
    case 'zh':
      return zhCN;
    case 'en':
      return enUS;
    default:
      return zhCN;
  }
}

function render (): void {
  root.render(
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={getAntdLocale()}
          theme={{ token: { ...defaultData } }}
        >
          <App />
        </ConfigProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

render();

store.subscribe(() => {
  updateLanguage();
  render();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
