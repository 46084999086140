import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState, AppThunk } from '@/store'; // 类型导入,并不造成 循环导入

export interface Tab {
  label?: string
  key?: string
  path?: string
  [key: string]: any
}

export interface TabState {
  curTab: any[]
  currentTab?: string | undefined
  status?: 'idle' | 'loading'
  reloadPath: string // 需要刷新的tab路径
  initTab: Tab
}

const initialState: TabState = {
  curTab: [],
  reloadPath: 'null', // 需要刷新的tab路径
  initTab: {
    label: 'blank page',
    key: '/',
    path: '/'
  }
};

export const tabSlice = createSlice({
  name: 'tab',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTabs: (state, action: PayloadAction<any[]>) => {
      state.curTab = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setReloadPath: (state, action) => {
      state.reloadPath = action.payload;
    },
    setInitTab: (state, action) => {
      state.initTab = action.payload;
    }
  }
});

export const { setTabs, setInitTab, setCurrentTab, setReloadPath } = tabSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTabs = (state: RootState): TabState['curTab'] => state.tab.curTab;
export const selectCurrentTab = (state: RootState): TabState['currentTab'] => state.tab.currentTab;
export const selectReloadPath = (state: RootState): TabState['reloadPath'] => state.tab.reloadPath;
export const selectInitTab = (state: RootState): TabState['initTab'] => state.tab.initTab;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const setTabTitle =
  (title: string): AppThunk =>
    (dispatch, getState) => {
      const currentValue = selectCurrentTab(getState());
      console.log('更新标题', currentValue, '->', title);
      dispatch(setCurrentTab(title));
    };
export default tabSlice.reducer;
